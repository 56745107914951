import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Sustainability Education and Training"
const PAGE_NAME = "education"
const EducationPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-12">

			<p>
				This program focuses on formal and informal education systems with an aim to provide all children with basic, quality education. AJVDC also promotes the education of adults and leaders, including the promotion of sustainable development and environmentally sound practices.
			</p>
			<p>
			This includes projects such as:
			</p>
            <ul>
			  <li>
			    Those targeting the establishment of libraries in primary and secondary schools as well as community centres.
			  </li>
			  <li>
				Those targeting the creation of school gardens and enhancing the skills of teachers in sustainable development 
			  </li>
			  <li>
				Project improving access to drinking water in the outskirts of Goma.
			  </li>
			</ul>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
		  <br /><br /><br />
            <h2>
				Training programs
			</h2>
			
			<p>
              More specifically, this program aims to deliver services and assistance to
              young entrepreneurs through education initiatives such as:{" "}
            </p>
            <ul>
              <li>Help with business basics</li>
              <li>Networking activities</li>
              <li>Marketing assistance</li>
              <li>High-speed Internet access</li>
              <li>Help with accounting/financial management</li>
              <li>Access to bank loans, loan funds and guarantee programs</li>
              <li>Links to strategic partners</li>
              <li>Access to angel investors or venture capital</li>
              <li>Comprehensive business training programs</li>
              <li>Advisory boards and mentors</li>
              <li>Management team identification</li>
              <li>Technology commercialization assistance</li>
              <li>Help with regulatory compliance</li>
              <li>Intellectual property management</li>
            </ul>
          </div>

          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image28"} />
			<br /><br />
			<Image imageKey={"image29"} />
          </div>
        </div>
		
<div className="row">
          <div className="col-sm-6">

              <br /><br />
			  <h2>Women and Youth Empowerment Initiative</h2>
              <br />

            <p>
              This project aims to improve the educational, healthy, social and
              economical life of disadvantaged children ex-combatants and women
              survival of sexual violence in the Eastern of Congo. This
              initiative is among youth civic engagement projects of AJVDC Youth
              Volunteer peace group. <br />
              <br />
              Specific objectives are to empower these youth and women and to
              inculcate entrepreneurial skills in them, to make them
              self-employed, and initiate them to be engaged in non-violent
              activities, in sporting activities, in small business
              (agriculture, fishing, micro –credit revolving fund), vocational
              skills training and other similar innovative and creative
              projects.
            </p>
          </div>

          <div className="col-sm-6">
            <h2>
              <br /><br /><br /><br />
            </h2>
            <Image imageKey={"women47"} />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"image12"} />
          </div>

          <div className="col-sm-6">
			<br />
            <Image imageKey={"women74249"} />
          </div>
        </div>
		
      </section>
    </Layout>
  )
}

export default EducationPage
